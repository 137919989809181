import React from 'react'
import i18n from 'config/i18n'
import { Redirect, Layout, Hero, Container, Section } from 'components'
import locales from '../../config/locales'
import aboutPortait from 'images/about-portrait.jpg'
import aboutMood from 'images/about-mood.jpg'
import heroImage from 'images/about-hero.jpg'
// Photo by Eric Wang on Unsplash

const images = [{ src: aboutMood }, { src: aboutPortait }]

export const AboutPage = ({ language = i18n.fallback }) => {
  const locale = locales[language]
  const page = locale.pages.about
  return (
    <Layout language={language}>
      <Hero
        backgroundImage={heroImage}
        backgroundPosition={{ _: 'center 40%', sm: 'center 3%' }}
      />
      <Container marginBottom={8} marginTop={2}>
        <Section type="text" {...page.vision} />
        <Section type={'images'} images={images} />
        <Section type={'text'} {...page.biography} />
      </Container>
    </Layout>
  )
}

export default Redirect
